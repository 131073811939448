/* WelcomeComponent.js */

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import 'styled-components/macro';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-1.svg';
import CreateList from 'components/modals/CreateList';

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 pt-20`;
const LeftColumn = tw.div`relative lg:w-9/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Actions = tw.div`relative max-w-md text-center sm:text-left lg:mx-0`;
const CreateListButton = tw.button`inline-block right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 px-6 sm:px-4 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}`;
// const CreateItemButton = tw.button`inline-block right-0 top-0 bottom-0 bg-secondary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 px-6 sm:px-4 sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`;

export default () => {
  const [createListModalOpen, setCreateListModelOpen] = useState(false)
  const toggleCreateListModal = useCallback(() => setCreateListModelOpen(!createListModalOpen), [setCreateListModelOpen, createListModalOpen])

  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <Heading>
            Bem-vindo ao <span tw="text-primary-500">Smartlist</span>
          </Heading>
          <Actions>
            <CreateListButton onClick={toggleCreateListModal}>Criar lista</CreateListButton>
            {/* <CreateItemButton>Criar item</CreateItemButton> */}
          </Actions>
        </LeftColumn>

        <RightColumn>
          {/* <IllustrationContainer>
            <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
          </IllustrationContainer> */}
        </RightColumn>
      </TwoColumn>
      <DecoratorBlob1 />

      <CreateList
        isOpen={createListModalOpen}
        toggle={toggleCreateListModal}
      />
    </Container>
  );
}
