import React from 'react'
import { Switch } from 'react-router-dom'
import Route from 'routes/Route'

import Signin from 'pages/auth/Signin'
import Signup from 'pages/auth/Signup'
import ForgotPassword from 'pages/auth/ForgotPassword'
import Home from 'pages/home/Home'

import PrivacyPolicy from 'pages/legal/PrivacyPolicy'
import TermsOfService from 'pages/legal/TermsOfService'

import Dashboard from 'pages/dashboard/Dashboard'
import List from 'pages/lists/List'
import Lists from 'pages/lists/Lists'
import Support from 'pages/support/Support'
import Account from 'pages/account/Account'

export default function Routes() {
  return (
    <Switch>

      <Route path="/login" isLogin isPublic exact component={Signin} />
      <Route path="/signup" isPublic exact component={Signup} />
      <Route path="/cadastro" isPublic exact component={Signup} />
      <Route path="/forgot" isPublic exact component={ForgotPassword} />
      <Route path="/esqueci-a-senha" isPublic exact component={ForgotPassword} />

      <Route path="/privacy-policy" isPublic exact component={PrivacyPolicy} />
      <Route path="/politica-de-privacidade" isPublic exact component={PrivacyPolicy} />
      <Route path="/terms-of-service" isPublic exact component={TermsOfService} />
      <Route path="/termos-de-servico" isPublic exact component={TermsOfService} />
      <Route path="/support" exact component={Support} />
      <Route path="/suporte" exact component={Support} />

      <Route path="/" exact component={Home} />
      <Route path="/app" isPrivate exact component={Dashboard} />
      <Route path="/lists" isPrivate exact component={Lists} />
      <Route path="/list/:id" isPrivate exact component={List} />
      <Route path="/account" isPrivate exact component={Account} />

    </Switch>
  )
}
