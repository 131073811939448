import React, { useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'
import { BasicButton as BasicButtonBase } from 'components/misc/Buttons'
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg'
import { ReactComponent as TrashIcon } from 'feather-icons/dist/icons/trash-2.svg'
import { ReactComponent as SquareIcon } from 'feather-icons/dist/icons/square.svg'
import { ReactComponent as CheckSquareIcon } from 'feather-icons/dist/icons/check-square.svg'
import { ReactComponent as SlidersIcon } from 'feather-icons/dist/icons/sliders.svg'
import { ReactComponent as EditIcon } from 'feather-icons/dist/icons/edit.svg'
import { ReactComponent as ArrowUpIcon } from 'feather-icons/dist/icons/arrow-up.svg'

import CreateItem from 'components/forms/CreateItem'
import EditList from 'components/modals/EditList'
import EditItem from 'components/modals/EditItem'

import api from 'services/api'
import { toast } from 'react-toastify'
import { useAuth } from 'context/Auth'
import ItemsStatuses from 'components/enums/ItemsStatuses'

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`
const HeadingContainer = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between mb-1`
const Heading = tw.h2`text-3xl tracking-wide text-center`
const HeadingInfo = tw.h2`text-xl tracking-wide`
const Controls = tw.div`items-center`
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-2 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`
const ControlButtonGray = styled(BasicButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-2 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`
const CardContainer = tw.div`relative mt-1 pt-5`
const Card = tw.div`max-w-full relative focus:outline-none py-4 px-4 bg-gray-200 rounded-md mb-3`
const InfoCard = tw.div`max-w-full relative focus:outline-none py-4 px-4 bg-blue-200 rounded-md mb-3 mt-5`

const CardContentContainer = tw.div`flex flex-col flex-row justify-between sm:items-center`
const TitleContainer = styled.div`${tw`flex items-center`}`
const Title = tw.p`text-base break-words`
const IconContainer = tw.div`cursor-pointer flex`
const IconDiv = tw.div`mr-4`
const IconContainer2 = tw.div`cursor-pointer mr-2`
const ButtonsContainer = tw.div`mt-2`
const RemoveButton = tw.button`bg-red-500 text-gray-100 font-bold mr-2 my-4 rounded-md py-2 px-4 sm:px-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-red-800 transition duration-300`
const EditButton = tw.button`bg-gray-500 text-gray-100 font-bold mr-2 my-4 rounded-md py-2 px-4 sm:px-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-gray-800 transition duration-300`

const InfoContainer = styled.div`${tw`flex items-center`}`
const Info = tw.span`ml-2 font-bold`

export default ({ listId, parentSetName }) => {
  const history = useHistory()

  const { toggleLoading } = useAuth()

  const [createItemOpen, setCreateItemOpen] = useState(false)
  const toggleCreateItem = useCallback(() => setCreateItemOpen(!createItemOpen), [setCreateItemOpen, createItemOpen])

  const [editListOpen, setEditListOpen] = useState(false)
  const toggleEditList = useCallback(() => setEditListOpen(!editListOpen), [setEditListOpen, editListOpen])

  const [editListModal, setEditListModal] = useState(false)
  const toggleEditListModal = useCallback(() => setEditListModal(!editListModal), [setEditListModal, editListModal])

  const [editItemModal, setEditItemModal] = useState(false)
  const toggleEditItemModal = useCallback(() => setEditItemModal(!editItemModal), [setEditItemModal, editItemModal])

  const [currentStatus, setCurrentStatus] = useState(ItemsStatuses.TODO);

  const [currentItem, setCurrentItem] = useState(null)

  const [listName, setListName] = useState('')
  const [listMessage, setListMessage] = useState('')
  const [items, setItems] = useState([])

  const getItems = useCallback(() => {
    toggleLoading(true)
    api.post('/get_items_by_list', { list_id: listId, status: currentStatus })
      .then((response) => {
        if (response.data && response.data.items) {
          setItems(response.data.items)
          if (response.data.items.length === 0) {
            setListMessage(response.data.message)
          }
        }
        if (response.data && response.data.list_name) {
          setListName(response.data.list_name)
          if (parentSetName) {
            parentSetName(response.data.list_name);
          }
        }
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [listId, setItems, setListName, setListMessage, toggleLoading, parentSetName, currentStatus])
  
  useEffect(() => getItems(), [getItems])

  const completeItem = useCallback((id) => {
    toggleLoading(true)
    api.post('/mark_item_as_complete', { item_id: id })
      .then((response) => {
        let message = 'Success'
        if (response && response.data && response.data.message) {
          message = response.data.message
        }
        toast.success(message)
        getItems()
      })
      .catch((err) => {
        let message = 'Error'
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message
        }
        toast.error(message)
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [getItems, toggleLoading])

  const removeItem = useCallback((id) => {
    toggleLoading(true)
    api.post('/remove_item', { item_id: id })
      .then((response) => {
        let message = 'Success'
        if (response && response.data && response.data.message) {
          message = response.data.message
        }
        toast.success(message)
        getItems()
      })
      .catch((err) => {
        let message = 'Error'
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message
        }
        toast.error(message)
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [getItems, toggleLoading])

  const removeList = useCallback(() => {
    toggleLoading(true)
    api.post('/remove_list', { list_id: listId })
      .then((response) => {
        let message = 'Success'
        if (response && response.data && response.data.message) {
          message = response.data.message
        }
        toast.success(message)
        history.push(`/lists`)
      })
      .catch((err) => {
        let message = 'Error'
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message
        }
        toast.error(message)
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [listId, toggleLoading, history])

  const increaseItemPriority = useCallback((id) => {
    let newPriority = 0

    if (items && items.length > 0) {
      const currentMaxPriority = parseInt(items[0].priority)
      if (currentMaxPriority > 0 || currentMaxPriority === 0) {
        newPriority = currentMaxPriority + 1
      }
    }

    if (newPriority === 0) {
      newPriority++
    }

    toggleLoading(true)
    api.post('/change_item_priority', { item_id: id, priority: newPriority })
      .then((response) => {
        getItems()
      })
      .catch((err) => {
        let message = 'Error'
        if (err && err.response && err.response.data && err.response.data.message) {
          message = err.response.data.message
        }
        toast.error(message)
      })
      .finally(() => {
        toggleLoading(false)
      })
  }, [getItems, toggleLoading, items])

  return (
    <Container>
      <Content>
        <HeadingContainer>
          <Heading>{listName}</Heading>
          <Controls>
            <ControlButtonGray onClick={toggleEditList}>
              <SlidersIcon />
            </ControlButtonGray>
            <ControlButton onClick={toggleCreateItem}>
              <PlusIcon />
            </ControlButton>
          </Controls>
        </HeadingContainer>

        {createItemOpen && (
          <CreateItem callback={getItems} listId={listId} />
        )}

        {editListOpen && (
          <InfoCard>
            <HeadingInfo>{listName}</HeadingInfo>
            <ButtonsContainer>
              <RemoveButton
                onClick={removeList}
              >
                Excluir lista
              </RemoveButton>
              <EditButton
                onClick={toggleEditListModal}
              >
                Editar lista
              </EditButton>
              {currentStatus !== ItemsStatuses.COMPLETE && (
                <EditButton
                  onClick={() => setCurrentStatus(ItemsStatuses.COMPLETE)}
                >
                  Concluídos
                </EditButton>
              )}
              {currentStatus !== ItemsStatuses.TODO && (
                <EditButton
                  onClick={() => setCurrentStatus(ItemsStatuses.TODO)}
                >
                  Abertos
                </EditButton>
              )}
            </ButtonsContainer>
          </InfoCard>
        )}

        <CardContainer>
          {listMessage !== '' && items.length === 0 && (
            <p>{listMessage}</p>
          )}

          {items.map((item) => (
            <Card key={item.id}>
              <CardContentContainer>
                <TitleContainer>
                  <IconContainer2>
                    {item.status === '1' && (
                      <SquareIcon
                        onClick={() => {
                          item.status = '2'
                          completeItem(item.id)
                        }}
                      />
                    )}
                    {item.status === '2' && (
                      <CheckSquareIcon />
                    )}
                  </IconContainer2>
                  <Title>{item.title}</Title>
                </TitleContainer>

                <InfoContainer>
                  <Info>
                    <IconContainer>
                      <IconDiv>
                        <ArrowUpIcon
                          onClick={() => increaseItemPriority(item.id)}
                        />
                      </IconDiv>
                      <IconDiv>
                        <EditIcon
                          onClick={() => {
                            toggleEditItemModal()
                            setCurrentItem(item)
                          }}
                        />
                      </IconDiv>
                      <TrashIcon
                        onClick={() => removeItem(item.id)}
                      />
                    </IconContainer>
                  </Info>
                </InfoContainer>
              </CardContentContainer>
            </Card>
          ))}
        </CardContainer>

        <EditList
          isOpen={editListModal}
          toggle={toggleEditListModal}
          name={listName}
          listId={listId}
          callback={() => setTimeout(() => document.location.reload(), 1000)}
        />

        <EditItem
          isOpen={editItemModal}
          toggle={toggleEditItemModal}
          item={currentItem}
          callback={getItems}
        />

      </Content>
    </Container>
  )
}